body {
  font-family: 'Lato' !important;
}

.blur-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Dark overlay */
  backdrop-filter: blur(5px); /* Adjust the blur strength as needed */
  z-index: 999; /* Ensure it's above the background but below the chatbox */
}

/* Global CSS or in your CSS file */
::-webkit-scrollbar {
  width: 8px; /* Change the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #1A9E7F; /* Primary color (blue) */
  border-radius: 10px; /* Optional: make it rounded */
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1); /* Background for the track */
}


@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
    font-family: 'Lato', sans-serif !important; /* Apply Lato font globally */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Additional styles can be added below */
