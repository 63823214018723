#home {
  padding: 100px 0;
  height: 100vh;
}
#contact {
  padding: 100px 0;
  min-height: 70vh; /* Ensures responsiveness in height */
}

/* Add media queries for further refinement */
@media (max-width: 600px) {
  #home,
  #contact {
    padding: 50px 0; /* Adjust padding on smaller screens */
  }
}

body {
  font-family: 'Lato' !important;
}

